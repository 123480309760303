<template>
  <div>
    <header-entry>考研资料阅读</header-entry>
    <div class="content-viewer">
      <div class="content-between">
        <div class="document-bar-box">
          <img class="document-logo" src="@/assets/images/pc/pdf.png" />
          <span class="document-name">{{ documentName }}</span>
        </div>
        <div v-if="documentName" class="document-bar-box">
          <el-button v-if="!unlocked && isPayPower" type="primary" size="small" class="iconfont icon-yuedu" @click="onPay">付费阅读</el-button>
          <el-button v-if="!unlocked" type="primary" size="small" class="iconfont icon-kefu" @click="onRegist">联系商家阅读</el-button>
          <el-button v-else type="primary" size="small" class="iconfont icon-print" @click="onPrint">打印</el-button>
        </div>
      </div>
      <document-viewer ref="viewer" class="document-viewer" :loading="loading" :percentage="percentage"></document-viewer>
    </div>
    <div class="content-list">
      <template v-if="firstDocumentInfos.length > 0">
        <header-entry>初试资料（点击可预览）</header-entry>
        <div class="content-border content-document">
          <template v-for="(val, key) in firstDocumentGroupList">
            <template v-if="val.length > 0">
              <div class="content-document-title">{{ key }}</div>
              <div v-for="docuemntInfo in val" class="content-document-item">
                <span class="content-document-item-name" @click="onDocumentClick(docuemntInfo)">{{ docuemntInfo.DocumentName }}</span>
                <span class="content-document-item-price">￥{{ docuemntInfo.DocumentPrice }}</span>
              </div>
            </template>
          </template>
        </div>
      </template>
      <template v-if="secondDocumentInfos.length > 0">
        <header-entry>复试资料（点击可预览）</header-entry>
        <div class="content-border content-document">
          <template v-for="docuemntInfo in secondDocumentInfos">
            <div class="content-document-item">
              <span class="content-document-item-name" @click="onDocumentClick(docuemntInfo)">{{ docuemntInfo.DocumentName }}</span>
              <span class="content-document-item-price">￥{{ docuemntInfo.DocumentPrice }}</span>
            </div>
          </template>
        </div>
      </template>
      <template v-if="courseInfos.length > 0">
        <header-entry>视频网课（点击可试看）</header-entry>
        <div class="content-border content-document">
          <template v-for="(val, key) in courseGroupList">
            <template v-if="val.length > 0">
              <div class="content-document-title">{{ key }}</div>
              <div v-for="courseInfo in val" class="content-document-item">
                <span class="content-document-item-name" @click="onCourseClick(courseInfo)">{{ courseInfo.CourseName }}</span>
                <span class="content-document-item-price">￥{{ courseInfo.CourseSellingPrice }}</span>
              </div>
            </template>
          </template>
        </div>
      </template>
      <template v-if="courseCustoms.length > 0">
        <header-entry>专属定制服务（点击可试看）</header-entry>
        <div class="content-border content-document">
          <template v-for="courseInfo in courseCustoms">
            <div class="content-document-item">
              <span class="content-document-item-name" @click="onCourseClick(courseInfo)">{{ courseInfo.CourseName }}</span>
              <span class="content-document-item-price">￥{{ courseInfo.CourseSellingPrice }}</span>
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import DocumentViewer from "@/components/PC/DocumentViewer";
import HeaderEntry from "@/components/PC/HeaderEntry";
import Tools from "@/tool";
import Config from "@/api/service";
import Manager from "@/manager";
export default {
  name: "PCModule",
  components: {
    DocumentViewer,
    HeaderEntry,
  },
  data() {
    return {
      loading: true,
      percentage: 0,
      unlocked: false,
      showRelatedCourse: false,
      showOnlinePayment: null,
      documentGuid: null,
      documentName: null,
      documentPassword: null,
      documentUrl: null,
      universityCode: null,
      majorCode: null,
      subjectCode: null,
      firstDocumentInfos: [],
      secondDocumentInfos: [],
      courseInfos: [],
      courseCustoms: [],
    };
  },
  computed: {
    isPayPower() {
      return this.showOnlinePayment == 1;
    },
    firstDocumentGroupList() {
      if (this.firstDocumentInfos) {
        var group = {
          全套精品: [],
          基础夯实: [],
          强化巩固: [],
          冲刺模拟: [],
          其他: [],
        };
        for (var i = 0; i < this.firstDocumentInfos.length; i++) {
          if (this.firstDocumentInfos[i].DocumentName.indexOf('【电子书】') >= 0 || this.firstDocumentInfos[i].DocumentName.indexOf('【初试】') >= 0) {
            group.全套精品.push(this.firstDocumentInfos[i]);
          } else if (this.firstDocumentInfos[i].DocumentName.indexOf('【基础】') >= 0) {
            group.基础夯实.push(this.firstDocumentInfos[i]);
          } else if (this.firstDocumentInfos[i].DocumentName.indexOf('【强化】') >= 0) {
            group.强化巩固.push(this.firstDocumentInfos[i]);
          } else if (this.firstDocumentInfos[i].DocumentName.indexOf('【冲刺】') >= 0) {
            group.冲刺模拟.push(this.firstDocumentInfos[i]);
          } else {
            group.其他.push(this.firstDocumentInfos[i]);
          }
        }
        return group;
      }
    },
    courseGroupList() {
      if (this.courseInfos) {
        var group = {
          全程班: [],
          导学课: [],
          基础班: [],
          强化班: [],
          冲刺班: [],
          其他: [],
        };
        for (var i = 0; i < this.courseInfos.length; i++) {
          if (this.courseInfos[i].CourseTag == "全程班") {
            group.全程班.push(this.courseInfos[i]);
          } else if (this.courseInfos[i].CourseTag == "导学课") {
            group.导学课.push(this.courseInfos[i]);
          } else if (this.courseInfos[i].CourseTag == "基础班") {
            group.基础班.push(this.courseInfos[i]);
          } else if (this.courseInfos[i].CourseTag == "强化班") {
            group.强化班.push(this.courseInfos[i]);
          } else if (this.courseInfos[i].CourseTag == "冲刺班") {
            group.冲刺班.push(this.courseInfos[i]);
          } else {
            group.其他.push(this.courseInfos[i]);
          }
        }
        return group;
      }
    },
  },
  methods: {
    //获取路由参数
    getRoutParams() {
      this.documentGuid = this.$route.params.documentGuid;
    },
    //获取是否显示关联课程列表
    getIsShowRelatedCourse() {
      var model = {
        ProxyCode: this.$store.state.proxyCode,
      };
      return this.$axios
        .post(Config.proxy.relatedCourseVisible, model)
        .then((res) => {
          this.showRelatedCourse = res.data.Data.ShowRelatedCourse;
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //获取是否购买文档
    getDocumentPurchase() {
      if (!this.$store.getters.isLogin) {
        return;
      }
      var model = {
        DocumentGuid: this.documentGuid,
      };
      return this.$axios
        .post(Config.document.purchased, model)
        .then((res) => {
          this.unlocked = res.data.Data.IsPurchased;
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //获取代理商信息
    getProxyInfo() {
      var model = {
        ProxyCode: this.$store.state.proxyCode,
      };
      return this.$axios
        .post(Config.proxy.onlinePaymentVisible, model)
        .then((res) => {
          this.showOnlinePayment = res.data.Data.ShowOnlinePayment;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //查询资料信息
    getDocumentInfo() {
      var model = {
        DocumentGuid: this.documentGuid,
      };
      return this.$axios
        .post(Config.document.info, model)
        .then((res) => {
          var data = res.data.Data;
          this.documentName = data.DocumentName;
          this.universityCode = data.UniversityCode;
          this.majorCode = data.MajorCode;
          this.subjectCode = data.SubjectCode;
          window.document.title = data.DocumentName;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //获取关联文档列表
    getDocumentInfos() {
      //初试列表
      var model = {
        UniversityCode: this.universityCode,
        MajorCode: this.majorCode,
        SubjectCode: this.subjectCode,
      };
      this.$axios
        .post(Config.document.related.firstDocumentList, model)
        .then((res) => {
          this.firstDocumentInfos = res.data.Data.List;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
      //复试列表
      var model = {
        UniversityCode: this.universityCode,
        MajorCode: this.majorCode,
      };
      this.$axios
        .post(Config.document.related.secondDocumentList, model)
        .then((res) => {
          this.secondDocumentInfos = res.data.Data.List;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //获取关联课程列表
    getCourseInfos() {
      var model = {
        UniversityCode: this.universityCode,
        MajorCode: this.majorCode,
      };
      this.$axios
        .post(Config.document.related.courseList, model)
        .then((res) => {
          this.courseInfos = res.data.Data.List;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //获取关联课程定制列表
    getCourseCustoms() {
      var model = {
      };
      this.$axios
        .post(Config.document.related.customList, model)
        .then((res) => {
          this.courseCustoms = res.data.Data.List;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //获取文档密码
    getDocumentPassword() {
      if (!this.$store.getters.isLogin) {
        return;
      }
      var model = {
        DocumentGuid: this.documentGuid,
      };
      return this.$axios
        .post(Config.document.password, model)
        .then((res) => {
          this.documentPassword = res.data.Data.Password;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //下载文档
    getDocumentUrl() {
      if (!this.$store.getters.isLogin) {
        return;
      }
      this.loading = true;
      //下载文档
      var model = {
        DocumentGuid: this.documentGuid,
        ProxyCode: this.$store.state.proxyCode,
      };
      return this.$axios
        ({
          method: "get",
          url: Config.document.download,
          params: model,
          responseType: "arraybuffer",
          onDownloadProgress: (evt) => {
            this.percentage = parseInt((evt.loaded / evt.total) * 100);
          },
        })
        .then((res) => {
          this.documentUrl = window.URL.createObjectURL(new Blob([res.data]));
        })
        .catch((error) => {
          this.$message.error(error.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //加载文档
    loadDocument() {
      if (!this.$store.getters.isLogin) {
        return;
      }
      if (this.$refs.viewer) {
        this.$refs.viewer.open(this.documentGuid, this.documentUrl, this.documentPassword);
      }
    },
    //跳转三方域名预览
    gotoDocumentViewer() {
      window.location.href = Manager.goDocumentViewerUrl(this.$store.state.proxyCode, this.documentGuid, this.$store.state.token);
    },
    //添加访问商品记录
    addVisitProductRecord() {
      return Manager.addVisitProductRecord(this.documentName);
    },
    //添加访问学校记录
    addVisitSchoolRecord() {
      Manager.addVisitSchoolRecord({
        DocumentGuid: this.documentGuid,
        RecordModule: 4,
      });
    },
    //点击资料
    onDocumentClick(documentInfo) {
      this.$router.push({
        name: 'Document1',
        params: {
          proxyCode: this.$store.state.proxyCode,
          documentGuid: documentInfo.DocumentGuid
        }
      });
    },
    //点击课程
    onCourseClick(courseInfo) {
      this.$router.push({
        name: 'Course1',
        params: {
          proxyCode: this.$store.state.proxyCode,
          courseGuid: courseInfo.CourseGuid
        }
      });
    },
    //点击在线支付
    onPay() {
      if (!this.$store.getters.isLogin) {
        this.$loginDialog.show(true);
      } else {
        this.$router.push({
          name: "SubmitDocumentOrder",
          params: {
            proxyCode: this.$store.state.proxyCode
          },
          query: {
            productGuid: this.documentGuid
          }
        });
      }
    },
    //点击联系客服激活
    onRegist() {
      if (!this.$store.getters.isLogin) {
        this.$loginDialog.show(true);
      } else {
        this.$router.push({
          name: "Regist",
          params: {
            proxyCode: this.$store.state.proxyCode
          },
          query: {
            documentGuid: this.documentGuid
          }
        });
      }
    },
    //点击打印
    onPrint() {
      if (!this.$store.getters.isLogin) {
        this.$loginDialog.show(true);
      } else {
        this.$router.push({
          name: "Print",
          params: {
            proxyCode: this.$store.state.proxyCode
          },
          query: {
            documentGuid: this.documentGuid
          }
        });
      }
    }
  },
  async mounted() {
    try {
      await this.getRoutParams();
      await this.getDocumentPurchase();
      if (!this.unlocked) {
        await this.getProxyInfo();
        await this.getDocumentInfo();
        await this.getDocumentInfos();
        await this.getIsShowRelatedCourse();
        await this.getDocumentPassword();
        await this.getDocumentUrl();
        this.loadDocument();
        this.addVisitProductRecord();
        this.addVisitSchoolRecord();
        if (this.showRelatedCourse) {
          this.getCourseInfos();
          this.getCourseCustoms();
        }
      } else {
        this.gotoDocumentViewer();
      }
    } catch (error) {
      this.$message.error(error.message);
    }
  }
};
</script>
<style scoped>
.content-viewer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #ededf0;
  border: 1px solid lightgray;
}

.content-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 20px;
}

.document-bar-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
}

.document-logo {
  width: 15px;
  height: 15px;
}

.document-name {
  color: #1e1e1e;
  font-size: 14px;
  margin-left: 10px;
}

.document-viewer {
  border-top: 1px solid lightgray;
}

.iconfont::before {
  margin-right: 2px;
}

.content-list {
  margin-top: 50px;
}

.content-border {
  border: 1px solid #e6e6e6;
  padding: 10px 30px;
}

.content-document {
  margin: 10px 0;
}

.content-document-title {
  font-size: 14px;
  font-weight: 600;
  margin: 20px 0px 10px 0;
}

.content-document-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: #555;
  font-size: 14px;
  margin: 10px 0;
}

.content-document-item-name {
  flex: 1;
  margin-right: 20px;
  cursor: pointer;
  color: #555;
  font-size: 14px;
}

.content-document-item-name:hover {
  color: #fe5e03;
  text-decoration: underline;
  text-underline-offset: 0.2em;
}

.content-document-item-price {
  min-width: 40px;
  color: #555;
  font-size: 14px;
}
</style>