<template>
  <div class="view-box">
    <van-sticky>
      <title-bar2 :title="documentName" />
    </van-sticky>
    <div class="document-box">
      <document-viewer ref="viewer" class="document-viewer" :loading="loading" :percentage="percentage"></document-viewer>
    </div>
    <div class="bar-box">
      <van-goods-action id="bottomBar">
        <van-goods-action-icon icon="wap-home-o" text="首页" @click="onHome" />
        <van-goods-action-icon icon="chat-o" text="客服" @click="onRegist" />
        <van-goods-action-icon icon="orders-o" text="相关" @click="onRelated" />
        <van-goods-action-button type="danger" text="立即打印" v-if="unlocked" @click="onPrint" />
        <van-goods-action-button type="danger" text="付费阅读" v-if="!unlocked && showOnlinePayment" @click="onPay" />
        <van-goods-action-button type="warning" text="联系商家阅读" v-if="!unlocked" @click="onRegist" />
      </van-goods-action>
    </div>
    <van-popup v-model="popup" round position="bottom" class="popup-box">
      <van-cell-group :border="false" title="相关资料">
        <div v-if="firstDocumentInfos.length > 0" class="major-content">
          <h3 class="major-title1">初试资料（点击可预览）</h3>
          <template v-for="(val, key) in firstDocumentGroupList">
            <template v-if="val.length > 0">
              <div class="major-title2">{{ key }}</div>
              <div v-for="(docuemntInfo, index) in val">
                <div class="major-name" @click="onDocumentClick(docuemntInfo)">{{ docuemntInfo.DocumentName }}</div>
                <div class="major-price">全国统一零售价：￥{{ docuemntInfo.DocumentPrice }}</div>
                <div class="major-line" v-if="index < val.length - 1"></div>
              </div>
            </template>
          </template>
        </div>
        <div v-if="secondDocumentInfos.length > 0" class="major-content">
          <h3 class="major-title1">复试资料（点击可预览）</h3>
          <div v-for="(docuemntInfo, index) in secondDocumentInfos">
            <div class="major-name" @click="onDocumentClick(docuemntInfo)">{{ docuemntInfo.DocumentName }}</div>
            <div class="major-price">全国统一零售价：￥{{ docuemntInfo.DocumentPrice }}</div>
            <div class="major-line" v-if="index < secondDocumentInfos.length - 1"></div>
          </div>
        </div>
        <div v-if="courseInfos.length > 0" class="major-content">
          <h3 class="major-title1">视频网课（点击可试看）</h3>
          <template v-for="(val, key) in courseGroupList">
            <template v-if="val.length > 0">
              <div class="major-title2">{{ key }}</div>
              <div v-for="(courseInfo, index) in val">
                <div class="major-name" @click="onCourseClick(courseInfo)">{{ courseInfo.CourseName }}</div>
                <div class="major-price">全国统一零售价：￥{{ courseInfo.CourseSellingPrice }}</div>
                <div class="major-line" v-if="index < val.length - 1"></div>
              </div>
            </template>
          </template>
        </div>
        <div v-if="courseCustoms.length > 0" class="major-content">
          <h3 class="major-title1">专属定制服务（点击可试看）</h3>
          <div v-for="(courseInfo, index) in courseCustoms">
            <div class="major-name" @click="onCourseClick(courseInfo)">{{ courseInfo.CourseName }}</div>
            <div class="major-price">全国统一零售价：￥{{ courseInfo.CourseSellingPrice }}</div>
            <div class="major-line" v-if="index < courseCustoms.length - 1"></div>
          </div>
        </div>
      </van-cell-group>
    </van-popup>
  </div>
</template>

<script>
import TitleBar2 from "@/components/MT/TitleBar2";
import DocumentViewer from "@/components/MT/DocumentViewer";
import Tools from "@/tool";
import Config from "@/api/service";
import Manager from "@/manager";
export default {
  name: "MTModule",
  components: {
    TitleBar2,
    DocumentViewer,
  },
  data() {
    return {
      loading: true,
      popup: false,
      percentage: 0,
      unlocked: false,
      showRelatedCourse: false,
      userCode: null,
      proxyGuid: null,
      showOnlinePayment: null,
      documentGuid: null,
      documentName: null,
      documentPassword: null,
      documentUrl: null,
      universityCode: null,
      majorCode: null,
      subjectCode: null,
      firstDocumentInfos: [],
      secondDocumentInfos: [],
      courseInfos: [],
      courseCustoms: [],
    };
  },
  computed: {
    firstDocumentGroupList() {
      if (this.firstDocumentInfos) {
        var group = {
          全套精品: [],
          基础夯实: [],
          强化巩固: [],
          冲刺模拟: [],
          其他: [],
        };
        for (var i = 0; i < this.firstDocumentInfos.length; i++) {
          if (this.firstDocumentInfos[i].DocumentName.indexOf('【电子书】') >= 0 || this.firstDocumentInfos[i].DocumentName.indexOf('【初试】') >= 0) {
            group.全套精品.push(this.firstDocumentInfos[i]);
          } else if (this.firstDocumentInfos[i].DocumentName.indexOf('【基础】') >= 0) {
            group.基础夯实.push(this.firstDocumentInfos[i]);
          } else if (this.firstDocumentInfos[i].DocumentName.indexOf('【强化】') >= 0) {
            group.强化巩固.push(this.firstDocumentInfos[i]);
          } else if (this.firstDocumentInfos[i].DocumentName.indexOf('【冲刺】') >= 0) {
            group.冲刺模拟.push(this.firstDocumentInfos[i]);
          } else {
            group.其他.push(this.firstDocumentInfos[i]);
          }
        }
        return group;
      }
    },
    courseGroupList() {
      if (this.courseInfos) {
        var group = {
          全程班: [],
          导学课: [],
          基础班: [],
          强化班: [],
          冲刺班: [],
          其他: [],
        };
        for (var i = 0; i < this.courseInfos.length; i++) {
          if (this.courseInfos[i].CourseTag == "全程班") {
            group.全程班.push(this.courseInfos[i]);
          } else if (this.courseInfos[i].CourseTag == "导学课") {
            group.导学课.push(this.courseInfos[i]);
          } else if (this.courseInfos[i].CourseTag == "基础班") {
            group.基础班.push(this.courseInfos[i]);
          } else if (this.courseInfos[i].CourseTag == "强化班") {
            group.强化班.push(this.courseInfos[i]);
          } else if (this.courseInfos[i].CourseTag == "冲刺班") {
            group.冲刺班.push(this.courseInfos[i]);
          } else {
            group.其他.push(this.courseInfos[i]);
          }
        }
        return group;
      }
    },
  },
  methods: {
    //获取路由参数
    getRoutParams() {
      this.documentGuid = this.$route.params.documentGuid;
    },
    //获取是否显示关联课程列表
    getIsShowRelatedCourse() {
      var model = {
        ProxyCode: this.$store.state.proxyCode,
      };
      return this.$axios
        .post(Config.proxy.relatedCourseVisible, model)
        .then((res) => {
          this.showRelatedCourse = res.data.Data.ShowRelatedCourse;
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //获取是否购买文档
    getDocumentPurchase() {
      if (!this.$store.getters.isLogin) {
        return;
      }
      var model = {
        DocumentGuid: this.documentGuid,
      };
      return this.$axios
        .post(Config.document.purchased, model)
        .then((res) => {
          this.unlocked = res.data.Data.IsPurchased;
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //获取代理商信息
    getProxyInfo() {
      var model = {
        ProxyCode: this.$store.state.proxyCode,
      };
      return this.$axios
        .post(Config.proxy.onlinePaymentVisible, model)
        .then((res) => {
          this.showOnlinePayment = res.data.Data.ShowOnlinePayment;
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //查询资料信息
    getDocumentInfo() {
      var model = {
        DocumentGuid: this.documentGuid,
      };
      return this.$axios
        .post(Config.document.info, model)
        .then((res) => {
          var data = res.data.Data;
          this.documentName = data.DocumentName;
          this.universityCode = data.UniversityCode;
          this.majorCode = data.MajorCode;
          this.subjectCode = data.SubjectCode;
          window.document.title = data.DocumentName;
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //获取关联文档列表
    getDocumentInfos() {
      //初试列表
      var model = {
        UniversityCode: this.universityCode,
        MajorCode: this.majorCode,
        SubjectCode: this.subjectCode,
      };
      this.$axios
        .post(Config.document.related.firstDocumentList, model)
        .then((res) => {
          this.firstDocumentInfos = res.data.Data.List;
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
      //复试列表
      var model = {
        UniversityCode: this.universityCode,
        MajorCode: this.majorCode,
      };
      this.$axios
        .post(Config.document.related.secondDocumentList, model)
        .then((res) => {
          this.secondDocumentInfos = res.data.Data.List;
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //获取关联课程列表
    getCourseInfos() {
      var model = {
        UniversityCode: this.universityCode,
        MajorCode: this.majorCode,
      };
      this.$axios
        .post(Config.document.related.courseList, model)
        .then((res) => {
          this.courseInfos = res.data.Data.List;
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //获取关联课程定制列表
    getCourseCustoms() {
      var model = {
      };
      this.$axios
        .post(Config.document.related.customList, model)
        .then((res) => {
          this.courseCustoms = res.data.Data.List;
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //获取文档密码
    getDocumentPassword() {
      if (!this.$store.getters.isLogin) {
        return;
      }
      var model = {
        DocumentGuid: this.documentGuid,
      };
      return this.$axios
        .post(Config.document.password, model)
        .then((res) => {
          this.documentPassword = res.data.Data.Password;
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //下载文档
    getDocumentUrl() {
      if (!this.$store.getters.isLogin) {
        return;
      }
      this.loading = true;
      //下载文档
      var model = {
        DocumentGuid: this.documentGuid,
        ProxyCode: this.$store.state.proxyCode,
      };
      return this.$axios
        ({
          method: "get",
          url: Config.document.download,
          params: model,
          responseType: "arraybuffer",
          onDownloadProgress: (evt) => {
            this.percentage = parseInt((evt.loaded / evt.total) * 100);
          },
        })
        .then((res) => {
          this.documentUrl = window.URL.createObjectURL(new Blob([res.data]));
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //加载文档
    loadDocument() {
      if (!this.$store.getters.isLogin) {
        return;
      }
      if (this.$refs.viewer) {
        this.$refs.viewer.open(this.documentGuid, this.documentUrl, this.documentPassword);
      }
    },
    //跳转三方域名预览
    gotoDocumentViewer() {
      window.location.href = Manager.goDocumentViewerUrl(this.$store.state.proxyCode, this.documentGuid, this.$store.state.token);
    },
    //添加访问商品记录
    addVisitProductRecord() {
      return Manager.addVisitProductRecord(this.documentName);
    },
    //添加访问学校记录
    addVisitSchoolRecord() {
      Manager.addVisitSchoolRecord({
        DocumentGuid: this.documentGuid,
        RecordModule: 4,
      });
    },
    //首页
    onHome() {
      this.$router.push({ name: "Home" });
    },
    //点击在线支付
    onPay() {
      if (!this.$store.getters.isLogin) {
        this.$loginDialog.show(true);
      } else {
        this.$router.push({
          name: "SubmitDocumentOrder",
          params: {
            proxyCode: this.$store.state.proxyCode
          },
          query: {
            productGuid: this.documentGuid
          }
        });
      }
    },
    //点击联系客服激活
    onRegist() {
      if (!this.$store.getters.isLogin) {
        this.$loginDialog.show(true);
      } else {
        this.$router.push({
          name: "Regist",
          params: {
            proxyCode: this.$store.state.proxyCode
          },
          query: {
            documentGuid: this.documentGuid
          }
        });
      }
    },
    //点击打印
    onPrint() {
      if (!this.$store.getters.isLogin) {
        this.$loginDialog.show(true);
      } else {
        this.$router.push({
          name: "Print",
          params: {
            proxyCode: this.$store.state.proxyCode
          },
          query: {
            documentGuid: this.documentGuid
          }
        });
      }
    },
    //点击相关资料
    onRelated() {
      this.popup = true;
    },
    //点击资料
    onDocumentClick(documentInfo) {
      this.$router.push({
        name: 'Viewer',
        params: {
          proxyCode: this.$store.state.proxyCode
        },
        query: {
          documentGuid: documentInfo.DocumentGuid
        }
      });
    },
    //点击课程
    onCourseClick(courseInfo) {
      this.$router.push({
        name: 'Course1',
        params: {
          proxyCode: this.$store.state.proxyCode,
          courseGuid: courseInfo.CourseGuid
        }
      });
    },
  },
  async mounted() {
    try {
      await this.getRoutParams();
      await this.getDocumentPurchase();
      if (!this.unlocked) {
        await this.getProxyInfo();
        await this.getDocumentInfo();
        await this.getDocumentInfos();
        await this.getIsShowRelatedCourse();
        await this.getDocumentPassword();
        await this.getDocumentUrl();
        this.loadDocument();
        this.addVisitProductRecord();
        this.addVisitSchoolRecord();
        if (this.showRelatedCourse) {
          this.getCourseInfos();
          this.getCourseCustoms();
        }
      } else {
        this.gotoDocumentViewer();
      }
    } catch (error) {
      this.$dialog({ title: "错误", message: error.message });
    }
  }
};
</script>
<style scoped>
.view-box {
  height: 100%;
}

.document-box {
  width: 100%;
  height: calc(100% - 100px);
}

.document-viewer {
  height: 100%;
}

.bar-box {
  height: 50px;
}

.van-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.down-progress {
  width: 90%;
}

.down-describe {
  margin-top: 20px;
  color: white;
  font-size: 14px;
}



.major-content {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}

.major-title1 {
  font-size: 18px;
  font-weight: 500;
}

.major-title2 {
  font-size: 15px;
  font-weight: 600;
  margin: 10px 0px 10px 0;
}

.major-name {
  color: gray;
  font-size: 14px;
  margin-bottom: 5px;
}

.major-name:hover {
  color: #FE5E03;
}

.major-price {
  width: 100%;
  display: inline-block;
  text-align: right;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: gray;
  font-size: 14px;
}

.major-line {
  border-bottom: 1px solid #e6e6e6;
  margin: 10px 0px;
}

.popup-box {
  height: 80%;
}

::v-deep .van-cell-group__title {
  color: black;
  font-weight: bold;
}
</style>